<template>
  <fragment>
    <header class="action-container">
      <v-row>
        <v-col cols="12" sm="8" md="6" lg="5" xl="4">
          <wx-text-field
            v-model="search"
            :label="$t('common.search')"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
            clearable
          />
        </v-col>
      </v-row>
    </header>

    <wx-data-table
      :headers="computedProductionUnitColumns"
      :items="computedProductionUnits"
      :items-per-page="productionUnitsPerPage"
      :search="search"
      :filterable="true"
      :sort-by="['name', 'downtimeTimeout']"
      :sort-desc="[false, true]"
      :no-results-text="this.$t('common.noMatchingRecords')"
      :hide-default-footer="computedProductionUnits.length <= productionUnitsPerPage"
      :footer-props="{
        itemsPerPageText: this.$t('productionUnit.listing.itemsPerPage'),
        itemsPerPageAllText: this.$t('common.listing.showAllOption'),
      }"
      :title="getRowTitle()"
      @click:row="redirectToEdition"
      mobile-breakpoint="sm"
      class="wx-panel"
    >
      <template #[`item.packages`]="{ item }">
        <v-chip v-for="(packageName, index) in item.packages" small outlined :key="`p-${index}`" class="mr-1">
          {{ packageName }}
        </v-chip>
      </template>
      <template #[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} {{ $t("common.listing.itemOfItems") }} {{ items.itemsLength }}
      </template>

      <template #[`item.actions`]="{ item }">
        <wx-btn-icon :title="$t('common.edit')" :to="toProductionUnitEdition(item.id, item.name)" class="my-sm-2 mr-sm-3" small>
          <v-icon small>mdi-pencil</v-icon>
        </wx-btn-icon>
        <wx-btn-icon
          :to="toProductionUnitMeasurementActivity(item.id)"
          @click.stop.prevent="toProductionUnitMeasurementActivity(item.id)"
          small
        >
          <v-icon>mdi-chevron-right</v-icon>
        </wx-btn-icon>
      </template>
      <template #no-data>
        {{ $t("productionUnit.listing.noData") }}
      </template>
    </wx-data-table>
  </fragment>
</template>

<script>
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import WxDataTable from "@/components/ui/WxDataTable";
import WxTextField from "@/components/ui/WxTextField";
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import { mapActions, mapGetters } from "vuex";
import { compareProductionUnitsByName } from "@/components/SortUtils";
import DurationUtils from "@/components/DurationUtils";
import DemoService from "@/components/DemoService";

export default {
  name: "ProductionUnitList",

  components: {
    WxDataTable,
    WxTextField,
    WxBtnIcon,
  },

  data() {
    return {
      search: "",
      productionUnits: [],
      productionUnitsPerPage: 10,
    };
  },

  computed: {
    ...mapGetters("navigation", ["activeFactory"]),
    ...mapGetters("user", ["email"]),
    computedProductionUnitColumns() {
      return [
        { text: this.$t("common.name"), class: "col-name", align: "start", value: "name" },
        { text: this.$t("productionUnit.listing.column.sf"), class: "col-sf", value: "packages" },
        {
          text: this.$t("productionUnit.details.downtimeDelay"),
          class: "col-time",
          value: "downtimeTimeoutAsDuration",
        },
        {
          text: this.$t("productionUnit.listing.column.convertedUnitName"),
          class: "col-unit",
          value: "convertedUnitName",
        },
        { text: this.$t("common.actions"), class: "col-actions", value: "actions", sortable: false, align: "right" },
      ];
    },
    computedProductionUnits() {
      return this.productionUnits.map((pu) => ({
        id: pu.id,
        name: pu.name,
        packages: pu.packages?.filter((p) => String(p)),
        downtimeTimeout: pu.downtime_timeout_in_seconds,
        downtimeTimeoutAsDuration: DurationUtils.secondsToHHMMSS(pu.downtime_timeout_in_seconds),
        convertedUnitName: ProductionUnitService.getUnitName(pu.converted_unit_name),
      }));
    },
  },
  watch: {
    activeFactory() {
      this.search = "";
      this.productionUnits = [];
      this.loadProductionUnits();
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    getRowTitle() {
      return this.$t("productionUnit.rowTitle");
    },
    redirectToEdition(productionUnit) {
      this.$router.push(RouteService.toProductionUnitEdition(productionUnit.id, productionUnit.name));
    },
    loadProductionUnits() {
      if (this.activeFactory) {
        ProductionUnitService.getProductionUnits(this.activeFactory.id)
          .then((response) => this.handleProductionUnitsRetrievalResponse(response))
          .catch((error) => this.handleProductionUnitsRetrievalError(error.response));
      }
    },
    handleProductionUnitsRetrievalResponse(httpResponse) {
      let puList = httpResponse.data;
      puList.forEach((x, index) => x.name = DemoService.maskProductionUnitNameIfNecessary(this.email, x.name, index + 1));
      puList.sort(compareProductionUnitsByName);
      this.productionUnits = puList;
    },
    handleProductionUnitsRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
    toProductionUnitEdition(productionUnitId, productionUnitName) {
      return RouteService.toProductionUnitEdition(productionUnitId, productionUnitName);
    },
    toProductionUnitMeasurementActivity(productionUnitId) {
      return RouteService.toProductionUnitMeasurementActivity(productionUnitId);
    },
  },
  mounted() {
    this.loadProductionUnits();
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  margin-bottom: 20px;
}

::v-deep table th {
  &.col-name {
    width: 25%;
  }
  &.col-sf {
    width: 20%;
  }
  &.col-actions {
    width: 18%;
  }
}
</style>
